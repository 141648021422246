import Vue from 'vue'

const urlService = '/api/services/app/Projects'

const Project = {
  // BINARY

  async getAll(filter) {
    const result = await Vue.prototype.$http.get(
      `${urlService}/GetAll?${filter}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    return result
  },
  async getForEdit(id) {
    const result = await Vue.prototype.$http.get(
      `${urlService}/GetProjectForEdit?id=${id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    return result
  },

  async createOrEdit(data) {
    return Vue.prototype.$http.post(`${urlService}/CreateOrEdit`, data, {
      headers: {
        accept: 'application/json',
        'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
      },
    })
  },

  async getCompanys() {
    const parans = {
      maxResultCount: 1000,
    }
    return Vue.prototype.$http.get('/api/services/app/UserConstructionCompanies/GetAll?SituationFilter=1', parans)
  },
  async getProjectTypes() {
    return Vue.prototype.$http.get('/api/services/app/ProjectTypes/GetAll')
  },
}

export default Project
