<template>
  <b-card>
    <Loader v-if="loading" />
    <b-form @submit.prevent>
      <validation-observer ref="validateForm">
          <b-row>
            <h3 style="padding-left: 1rem;">Cadastro Projeto/Obra</h3>
          </b-row>
          <b-row />
          <b-row>
            <b-col sm="4">
              <b-form-group label="Construtora" label-for="construction-company">
                <validation-provider #default="{ errors }" name="Construtora" rules="required">
                  <b-form-select id="construction-company" v-model="item.userConstructionCompanyId" :options="companys" placeholder="Construtora" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Nome do Projeto/Obra" label-for="project">
                <validation-provider #default="{ errors }" name="Nome do Projeto/Obra" rules="required">
                  <b-form-input id="project" v-model="item.name" placeholder="Nome do Projeto/Obra" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Edifício" label-for="edificio">
                <validation-provider #default="{ errors }" name="Edifício" rules="required">
                  <b-form-input id="edificio" v-model="item.edificio"  placeholder="Torre Sul" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <b-form-group label="Data de Início" label-for="start-date">
                <validation-provider #default="{ errors }" name="Data de Início" rules="required">
                <b-form-input
                  v-model="item.startDate"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Previsão de Entrega" label-for="delivery-forecast">
                <validation-provider #default="{ errors }" name="Previsão de Entrega" rules="required">
                <b-form-input
                  v-model="item.releaseDate"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Tipo de Construção" label-for="type-construction">
                <validation-provider #default="{ errors }" name="Tipo de Construção" rules="required">
                  <b-form-select id="type-construction" v-model="item.projectTypeId" :options="types" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd Pavimentos" label-for="qtd-paving">
                <validation-provider #default="{ errors }" name="Qtd Pavimentos" rules="required">
                  <b-form-input id="qtd-paving" v-model="item.pavementCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd Andares" label-for="andar-imovel">
                <validation-provider #default="{ errors }" name="Qtd Andares" rules="required">
                  <b-form-input id="qtd-floors" v-model="item.floorCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <b-form-group label="Qtd Andares Garagem" label-for="qtd-floors-garage">
                <validation-provider #default="{ errors }" name="Qtd Andares Garagem" rules="required">
                  <b-form-input id="qtd-floors-garage" v-model="item.parkingFloorCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="SPA" label-for="spe-immobile">
                <validation-provider #default="{ errors }" name="SPA" rules="required">
                  <b-form-select id="spe-immobile" v-model="item.hasSpe" :options="options" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="CNPJ da Obra" label-for="cnpj-immobile">
                <validation-provider #default="{ errors }" name="CNPJ da Obra" rules="required">
                  <b-form-input id="cnpj-immobile" v-model="item.cnpj" placeholder="00.000.000/0000-00" v-mask="['##.###.###/####-##']" :options="options" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Número de Incorporação" label-for="nro-incorporation">
                <validation-provider #default="{ errors }" name="Número de Incorporação" rules="required">
                  <b-form-input id="nro-incorporation" v-model="item.incorporationNumber" placeholder="00000-00" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Ofício" label-for="oficio">
                <validation-provider #default="{ errors }" name="Ofício Cartório" rules="required">
                  <b-form-input id="oficio" v-model="item.oficio" type="number" placeholder="2" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Livro" label-for="book">
                <validation-provider #default="{ errors }" name="Livro" rules="required">
                  <b-form-input id="book" v-model="item.book" placeholder="2" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Comarca" label-for="comarca">
                <validation-provider #default="{ errors }" name="Comarca" rules="required">
                  <b-form-input id="comarca" v-model="item.comarca" placeholder="Blumenau" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Metragem (m²)" label-for="footage-immobile">
                <validation-provider #default="{ errors }" name="Metragem" rules="required">
                  <percentual-input ref="totalArea" id="totalArea" :value="item.totalArea" @update="updateTotalArea"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd. Torres" label-for="qtd-towers">
                <validation-provider #default="{ errors }" name="Qtd. Torres" rules="required">
                  <b-form-input id="qtd-towersl" v-model="item.towerCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd. Unidades" label-for="qtd-unitys">
                <validation-provider #default="{ errors }" name="Qtd. Unidades" rules="required">
                  <b-form-input id="qtd-unitys" v-model="item.unitCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd. Vagas de Garagem" label-for="wave-immobile">
                <validation-provider #default="{ errors }" name="Qtd. Vagas de Garage" rules="required">
                  <b-form-input id="wave-immobile" v-model="item.parkingCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd. Elevadores Social" label-for="elevators-social">
                <validation-provider #default="{ errors }" name="Qtd. Elevadores Social" rules="required">
                  <b-form-input id="elevators-social" v-model="item.socialElevatorCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd. Elevadores Serviço" label-for="elevators-service">
                <validation-provider #default="{ errors }" name="Qtd. Elevadores Serviço" rules="required">
                  <b-form-input id="elevators-service" v-model="item.serviceElevatorCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Área Lazer (㎡)" label-for="leisure-area">
                <validation-provider #default="{ errors }" name="Área Lazer (㎡)" rules="required">
                  <percentual-input ref="recreationAreaCount" id="recreationAreaCount" :value="item.recreationAreaCount" @update="updateRecreationArea"  required :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd. Salas Comerciais" label-for="commercial-rooms">
                <validation-provider #default="{ errors }" name="Qtd. Salas Comerciais" rules="required">
                  <b-form-input id="commercial-rooms" v-model="item.businessUnitCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Qtd. Unidades por Andar" label-for="units-floor">
                <validation-provider #default="{ errors }" name="Qtd. Unidades por Andar" rules="required">
                  <b-form-input id="units-floor" v-model="item.unitPerFloorCount" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group v-if="isAdm() === true" label="Bloquear Edição" label-for="lock-edit">
                <b-form-select id="lock-edi" v-model="item.editLock" :options="options" required :state="errors.length > 0 ? false : null" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col>
              <h3>Áreas Sociais</h3>
            </b-col>
          </b-row>
          <b-form-checkbox-group v-model="item.socialArea">
            <b-row>
              <b-col sm="3">
                <b-form-checkbox value="recreation">Recreação</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="dym">Academia</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="pub">Pub</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="pet">Pet Place</b-form-checkbox>
              </b-col>
              <b-col sm="3">
                <b-form-checkbox value="game">Sala de Jogos</b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-checkbox value="roomGamePub">Sala de Jogos</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="petCare">Pet Care</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="miniCourt">Miniquadra</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="coworking">Coworking</b-form-checkbox>
              </b-col>
              <b-col sm="3">
                <b-form-checkbox value="toys">Brinquedoteca</b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-checkbox value="pool">Piscina</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="steamRoom">Sauna</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="movieTheater">Cinema</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="greeArea">Área Verde</b-form-checkbox>
              </b-col>
              <b-col sm="3">
                <b-form-checkbox value="gourmetArea">Área Gourmet</b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-checkbox value="wordArea">Área de Trabalho</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="beautySpace">Espaço de Beleza</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="lounge">Lounge</b-form-checkbox>
              </b-col>
              <b-col sm="2">
                <b-form-checkbox value="partyRoom">Salão de Festas</b-form-checkbox>
              </b-col>
              <b-col sm="3">
                <b-form-checkbox value="others">Outros</b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
          <b-row class="pt-1">
            <b-col>
              <b-form-textarea id="textarea" v-model="item.description" placeholder="Em caso de 'outros', descreva aqui" />
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col>
              <h3>Status da Obra</h3>
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col sm="3">
              <b-form-group label="Fundação (%)" label-for="foundation">
                <b-form-input id="foundation" v-model="item.foundation" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="Alvenaria (%)" label-for="masonry">
                <b-form-input id="masonry" v-model="item.masoury" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="Estrutural (%)" label-for="landscaping">
                <b-form-input id="landscaping" v-model="item.landscaping" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="Status total (%)" label-for="general-schedule">
                <b-form-input id="general-schedule" v-model="item.generalSchedule" type="number" placeholder="0" required :state="errors.length > 0 ? false : null" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col>
              <h3 class="photos-title">Fotos da Obra</h3>
              <b-form-file v-model="photo" accept="image/*" class="photos-file" placeholder="Escolha uma foto" @input="uploadPhoto()" />
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col>
              <div class="photos">
                <div v-for="(itemPhoto, index) in item.photos" ref="file_input" :key="index" class="photo" :style="{'background-image': 'url(data:image/jpeg;base64,'+itemPhoto.byte+')'}">
                  <b-form-input id="imput_photo" v-model="itemPhoto.description" type="text" class="title" />
                  <b-icon icon="trash-fill" aria-hidden="true" class="icon" font-scale="2" @click="removePhoto(index)" />
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-1 justify-content-end">
            <b-col sm="2">
              <b-button class="btn-block" variant="primary" @click="save()">Salvar</b-button>
            </b-col>
            <b-col sm="2">
              <b-button class="btn-block" variant="primary" @click="back()">Voltar</b-button>
            </b-col>
          </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>

import {
  BRow,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BIcon,
  BFormFile,
  BForm,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toast from '@/mixins/toast'
import formatter from '@/mixins/formatter'
import { required } from '@validations'
import Loader from '../components/Loader.vue'
import ProjectService from '../services/Project/ProjectService'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import PercentualInput from '../components/PercentualInput.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormTextarea,
    BButton,
    BIcon,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BForm,
    flatPickr,
    Loader,
    PercentualInput,
  },
  mixins: [toast, formatter],
  data() {
    return {
      error: '',
      errors: [],
      required,
      loading: false,
      photo: [],
      companys: [],
      config: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      item: {
        id: null,
        userConstructionCompanyId: null,
        project: null,
        startDate: null,
        releaseDate: null,
        projectTypeId: null,
        pavementCount: null,
        floorCount: null,
        parkingFloorCount: null,
        hasSpe: null,
        cnpj: null,
        incorporationNumber: null,
        totalArea: null,
        towerCount: null,
        unitCount: null,
        parkingCount: null,
        socialElevatorCount: null,
        serviceElevatorCount: null,
        recreationAreaCount: null,
        businessUnitCount: null,
        unitPerFloorCount: null,
        editLock: false,
        socialArea: [],
        foundation: 0,
        masoury: 0,
        landscaping: 0,
        generalSchedule: 0,
        description: null,
        photos: [],
      },
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      status: [
        { value: 'rejeitado', text: 'Rejeitado' },
        { value: 'pendente', text: 'Pendente de Informação' },
      ],
      types: [],
    }
  },
  async created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  async mounted() {
    this.loading = true
    if (this.$route.params.id) {
      const data = await ProjectService.getForEdit(this.$route.params.id)
      this.item = data.data.result.project
      this.item.startDate = formatter.flatPickrDateFormat(this.item.startDate)
      this.item.releaseDate = formatter.flatPickrDateFormat(this.item.releaseDate)
      if (this.item.socialArea === null) {
        this.item.socialArea = []
      }
    }
    await this.filterCompany()

    const typesProject = await ProjectService.getProjectTypes()
    this.types = typesProject.data.result.items.map(x => ({
      text: x.projectType.name,
      value: x.projectType.id,
    }))
    this.loading = false
  },
  methods: {

    isAdm(){
      return localStorage.getItem('role') === 'Admin'
    },
    async save() {
      const valid = (await this.$refs.validateForm.validate())

      if (valid) {
        this.loading = true
        try {
          const responseBank = await ProjectService.createOrEdit(this.item)
          if (responseBank) {
            if (!this.msgIsOpen()) {
              this.loading = false
              this.msgSuccessNoConfirm('Dados gravados com sucesso')
              this.$router.go(-1)
            }
          }
        } catch (error) {
          console.log(error.response)
          this.loading = false
          this.show = false
          if (!this.msgIsOpen()) this.msgError(error.response.data.error.message)
        }
      } else {
        this.msgError('Existem campos inválidos!')
      }
    },
    back() {
      this.$router.push({ path: '/adm/project' })
    },
    async filterCompany() {
      const data = await ProjectService.getCompanys(this.item.constructionCompany)
      this.companys = data.data.result.items.map(x => ({
        text: x.userConstructionCompany.name,
        value: x.userConstructionCompany.id,
      }))
    },
    uploadPhoto() {
      const file = this.photo
      const reader = new FileReader()
      reader.readAsDataURL(file)
      if (this.item.photos == null) {
        this.item.photos = []
      }
      const p = this.item.photos
      reader.onload = function () {
        const split = reader.result.split(',')
        const byte = split[1]
        p.push({
          description: file.name,
          byte,
          type: split[0],
        })
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    removePhoto(index) {
      this.item.photos.splice(index, 1)
    },
    updateTotalArea(value){
      this.item.totalArea = value 
    },
    updateRecreationArea(value){
      this.item.recreationAreaCount = value 
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

 .photos {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  .photos .photo {
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 5px;
    position: relative;
    border-radius: 10px;
  }

  .photos .photo .title {
    position: absolute;
    bottom: 0px;
    margin: 2px;
    width: calc(100% - 4px);
    background-color: rgba(255,255,255,.6);
    color: black;
  }
  .photos .photo .icon {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    color: orange;
    margin: 5px;
  }
  .photos .photo .icon:hover {
    color: white;
  }
  .photos-title,
  .photos-file {
    float:left;
  }
</style>
